<template>
  <VContainer class="main--body--content">
    <VRow v-show="form && form.id">
      <VCol cols="12">
        <h3 class="mb-2" v-if="form && form.id">{{ form.title }}</h3>
        <VSubheader v-if="form && form.id">
          Last updated: {{ form.updated_at | timeago }}
        </VSubheader>
        <div :id="id" class="gem-md-editor" />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import timeago from "@/plugins/timeago";
import docsAPI from "@/api/internal/docs.js";

// deps for editor
import "@toast-ui/editor/dist/toastui-editor.css";
import Editor from "@toast-ui/editor";
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import 'prismjs/themes/prism.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js';
import defaultOptions from "./default-options";

export default {
  name: "ArticleView",
  components: {},
  props: {
    value: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      required: false,
      default() {
        return (
          "markdown-editor-" +
          +new Date() +
          ((Math.random() * 1000).toFixed(0) + "")
        );
      }
    },
    options: {
      type: Object,
      default() {
        return defaultOptions;
      }
    },
    mode: {
      type: String,
      default: "markdown"
    },
    height: {
      type: String,
      required: false,
      default: "1200px"
    },
    language: {
      type: String,
      required: false,
      default: "en_US" // https://github.com/nhnent/tui.editor/tree/master/src/js/langs
    }
  },
  filters: {
    timeago(value) {
      return timeago(value, "en");
    }
  },
  data() {
    return {
      editor: null,
      form: null
    };
  },
  computed: {
    editorOptions() {
      const options = Object.assign({});
      options.hideModeSwitch = true;
      options.height = "auto";
      return options;
    }
  },
  created() {
    this.fetchData();
  },
  watch: {},
  mounted() {
    // this.initEditor();
  },
  destroyed() {},
  methods: {
    ...mapMutations({
      OpenLoading: "OpenLoading",
      CloseLoading: "CloseLoading",
      OpenError: "OpenError",
      OpenSuccess: "OpenSuccess"
    }),
    initEditor() {
      this.editor = new Editor({
        el: document.getElementById(this.id),
        plugins: [[codeSyntaxHighlight, { highlighter: Prism }]],
        ...this.editorOptions
      });
    },
    fetchData() {
      let articleId = this.$route.params.articleId;
      this.OpenLoading();
      this.loading = true;
      docsAPI
        .getArticle(articleId)
        .then(res => {
          this.CloseLoading();
          this.form = res.data.article;
          this.loading = false;
          this.initEditor();
          this.editor.setMarkdown(this.form.content);
          document.querySelector("[aria-label=Preview]").click();
        })
        .catch(err => {
          console.log(err);
          // this.$router.push({
          //   name: "Docs"
          // });
        });
    }
  }
};
</script>
<style lang="scss" scoped></style>
